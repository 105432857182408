<template>
  <div class='wrap'
       ref="wrap">
       <div class="icon"
            
             @click="closeWindow">
          <img src="@/assets/personalCenter/close.png"
               alt=""
               title="关闭">
        </div>
    <div class="top_move"
         ref="top"></div>
    <div class="top">
      <!--  @click="handler" -->
      <!-- <img src="@/assets/error_book/tip_top.png"
               alt=""> -->
    </div>
    <div class="star"
         v-if="windowData.title&&windowData.type!='lecture'&&windowData.type!='capability_type1'&&windowData.type!='capability_type2'">
      <div class="img1"
           v-for='index of windowData.star'
           :key='index'>
        <img src="@/assets/error_book/star.png"
             alt="">
      </div>
      <div class="img"
           v-for="index of 5-windowData.star"
           :key="'b'+index">
        <img src="@/assets/error_book/star_empty.png"
             alt="">
      </div>
    </div>
    <div class="title"
         v-if="windowData.title">
      <!-- 知识点的标题 -->
      {{windowData.title?windowData.title:'无'}}
    </div>
    <div class="bottom_tab">
      <div class="left">
        <el-progress type="circle"
                     :percentage="windowData.score?Number(windowData.score):0"
                     :show-text="false"
                     color="#fff">
        </el-progress>
        <div class="txt">
          <div class="num">{{windowData.score?windowData.score:"无"}}</div>
          <div class="txtRate">得分率</div>
        </div>
      </div>
      <div class="right">
        <div class="time">{{windowData.time}}</div>
        <div class="count">次数：{{windowData.count?windowData.count:"无"}}</div>
        <div class="operation">
          <img v-show="windowData.type=='lecture'"
               src="../../../assets/error_book/icon-讲义@2x.png"
               alt=""
               title="讲义"
               @click="readLecture">
          <img v-show="(windowData.type=='knowledge'||windowData.type=='checkPoint')&&windowData.video_list.length > 0&&userInfo.show_video!=0"
               src="../../../assets/error_book/icon-视频@2x.png"
               alt=""
               title="视频"
               @click="playVedio">
          <img src="../../../assets/error_book/icon-练习@2x.png"
               alt=""
               title="练习"
               v-show="windowData.type=='knowledge'||windowData.type=='checkPoint'||windowData.type=='capability_type1'||windowData.type=='capability_type2'"
               @click="doPaper">
        </div>
      </div>
    </div>
    <VideoForm ref="VideoFormRef" />
  </div>
</template>

<script>
import VideoForm from './videoCopy.vue'
// import { getPointTest } from '@/api/errorbasis.js'

export default {
  data () {
    return {
      num1: 4,
      oX: 0,
      oY: 0,
      windowData: {},
      userInfo: {},
      istouch: false
    }
  },
  components: {
    VideoForm
  },
  mounted () {
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    this.$refs.top.addEventListener("mousedown", this.handler)
    this.$refs.top.addEventListener("touchstart", this.handlerTouch, false)
  },
  methods: {
    closeWindow () {
      console.log('关闭',this.$parent);
      
      this.$parent.reset()
    },
    handler (e) {
      this.oX = e.pageX - this.$refs.wrap.offsetLeft
      this.oY = e.pageY - this.$refs.wrap.offsetTop
      // this.$refs.top.addEventListener("mousemove", this.move)
      document.addEventListener("mousemove", this.move,)
      document.addEventListener("mouseup", this.stop)
      this.istouch = false
    },
    move (e) {
      if (this.istouch) {
        // e.preventDefault()
        e.stopPropagation();
        document.addEventListener("touchend", this.stop)
        this.$refs.wrap.style.left = e.touches[0].pageX - this.oX + 'px'
        this.$refs.wrap.style.top = e.touches[0].pageY - this.oY + 'px'
      } else {
        document.addEventListener("mouseup", this.stop)
        this.$refs.wrap.style.left = e.pageX - this.oX + 'px'
        this.$refs.wrap.style.top = e.pageY - this.oY + 'px'
      }
      // console.log("移动", e)
    },
    stop (e) {
      // this.$refs.top.removeEventListener("mousemove", this.move)
      if (this.istouch) {
        document.removeEventListener("touchmove", this.move, false)
      } else {
        document.removeEventListener("mousemove", this.move)
      }

      // document.removeEventListener("mouseup", this.stop)
    },

    handlerTouch (e) {
      // e.preventDefault()
      e.stopPropagation();
      this.oX = e.touches[0].pageX - this.$refs.wrap.offsetLeft
      this.oY = e.touches[0].pageY - this.$refs.wrap.offsetTop
      // this.$refs.top.addEventListener("mousemove", this.move)
      document.addEventListener("touchmove", this.move, false)
      document.addEventListener("touchend", this.stop)
      this.istouch = true
    },
    // 视频
    playVedio () {
      if (this.windowData.video_list.length > 0) {
        this.$refs.VideoFormRef.tableData = this.windowData.video_list
        this.$refs.VideoFormRef.dialogVisible = true
      }
      else this.$message.warning("暂无视频")
    },
    // 练习
    doPaper () {
      //  3考点做卷(6) 5知识点训练(4) 
      // console.log("data", this.windowData)
      let params
      if (this.windowData.type == 'checkPoint') {
        params = {
          check_point_id: this.windowData.check_point_id,
          check_point_2_id: this.windowData.check_point_2_id,
          selecting_checkpoint: 0,
          type: 6
        }
        this.$router.push('/doPaper?type=3&form=' + JSON.stringify(params))
      }
      if (this.windowData.type == 'knowledge') {
        params = {
          knowledge_id: this.windowData.knowledge_id,
          selecting_checkpoint: 0,
          is_weak: 0,
          subject_id: this.windowData.subjectId,
          type: 4,
        }
        this.$router.push('/doPaper?type=5&form=' + JSON.stringify(params))
      }
      if (this.windowData.type == 'capability_type1') {
        params = {
          capability_id: this.windowData.capability_id,
          subject_id: this.windowData.subjectId,
          type: 17
        }
        this.$router.push('/doPaper?type=17&form=' + JSON.stringify(params))
      }
      if (this.windowData.type == 'capability_type2') {
        params = {
          capability_id: this.windowData.capability_id,
          subject_id: this.windowData.subjectId,
          type: 18
        }
        this.$router.push('/doPaper?type=18&form=' + JSON.stringify(params))
      }
    },
    readLecture () {
      window.open(this.windowData.lecture_file)
    }
  }

}
</script>

<style lang="scss" scoped>
.wrap {
  z-index: 999;
  width: 357rem;
  min-height: 388rem;
  position: absolute;
  top: 40rem;
  right: 40rem;

  // border-top: 10rem solid #2196f3;
  background: #ffffff;
  border-radius: 10rem;
  overflow: hidden;
  box-shadow: 0px 8px 19px 1px rgba(0, 0, 0, 0.2);
  .icon {
        position: absolute;
        top: -7rem;
        right: 0rem;
        width: 50rem;
        height: 50rem;
        z-index: 1000;
        cursor: pointer;
        img {
          width: 115%;
          height: 115%;
        }
      }
  .top_move {
    background-color: transparent;
    width: 100%;
    height: 180rem;
    position: absolute;
    top: 0;
    cursor: move;
  }
  .top {
    cursor: move;
    width: 100%;
    height: 18rem;
    background-image: url(../../../assets/error_book/tip_top.png);
    background-size: 100% 120%;
    background-color: #2196f3;
    img {
      width: 100%;
    }
  }
  .star {
    padding: 19rem 19rem 5rem;
    .img,
    .img1 {
      display: inline-block;
      // width: 29rem;
      // height: 28rem;
      width: 47rem;
      height: 46rem;
      // margin-right: 10rem;
    }
    .img {
      img {
        width: 60%;
        height: 60%;
        padding: 13%;
      }
    }
    .img1 {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .title {
    font-weight: bold;
    color: #333333;
    line-height: 36px;
    font-size: 26rem;
    padding: 0 19rem;
  }
  .bottom_tab {
    position: absolute;
    // bottom: -15rem;
    bottom: 5rem;
    // display: flex;
    color: #fff;
    .left {
      display: inline-block;
      width: 150rem;
      height: 150rem;
      margin-left: 10rem;
      position: relative;
      background: url("../../../assets/error_book/ratebg.png");
      background-size: 100% 100%;
      ::v-deep .el-progress {
        // margin-top: 20%;
        width: 100%;
      }
      ::v-deep .el-progress-circle {
        margin-top: 12%;
        margin-left: 13%;
        width: 75% !important;
        height: 75% !important;
      }
      .txt {
        width: 65%;
        height: 65%;
        text-align: center;
        position: absolute;
        top: 23%;
        left: 17%;
        color: #fff;
        :nth-child(1) {
          line-height: 50rem;
          font-size: 34rem;
        }
        :nth-child(2) {
          line-height: 28rem;
          font-size: 16rem;
        }
      }
    }

    .right {
      display: inline-block;
      vertical-align: top;
      // flex: 1;
      width: 249rem;
      min-height: 135rem;
      margin-top: 5rem;
      margin-left: -76rem;
      border-radius: 9rem;
      background: #ffffff;
      padding-left: 20rem;
      box-sizing: border-box;
      box-shadow: 0px 3px 8px 1px rgb(0 0 0 / 16%);
      .time {
        font-size: 17rem;
        line-height: 44rem;
        color: #999;
        text-align: right;
        padding-right: 20rem;
      }
      .count {
        font-size: 20rem;
        width: 100%;
        line-height: 40rem;
        text-align: center;
        padding-left: 20rem;
        box-sizing: border-box;
        height: 36rem;
        background: url("../../../assets/error_book/bg.png");
        background-size: 105% 150%;
      }
      .operation {
        padding: 13rem 13rem 10rem 45rem;
        img {
          width: 32rem;
          height: 32rem;
          margin-left: 19rem;
        }
      }
    }
  }
}
</style>